import '../styles/globals.css';
import '../node_modules/nprogress/nprogress.css';

import Router from 'next/router';
import { Provider as AuthProvider } from 'next-auth/client';
import NProgress from 'nprogress';
import { ToastProvider } from 'react-toast-notifications';

import CustomToast from '../components/custom-toast';
import { FetchProvider } from '../components/fetch-provider';
import { init } from '../lib/sentry';

init();

NProgress.configure({ showSpinner: false });

Router.onRouteChangeStart = () => {
	// console.log('onRouteChangeStart triggered');
	NProgress.start();
};

Router.onRouteChangeComplete = () => {
	// console.log('onRouteChangeComplete triggered');
	NProgress.done();
};

Router.onRouteChangeError = () => {
	// console.log('onRouteChangeError triggered');
	NProgress.done();
};

function MyApp({ Component, pageProps, err }) {
	return (
		<ToastProvider
			autoDismiss
			placement="top-center"
			components={{ Toast: CustomToast }}
		>
			<FetchProvider>
				<AuthProvider session={pageProps.session}>
					<Component {...pageProps} err={err} />
				</AuthProvider>
			</FetchProvider>
		</ToastProvider>
	);
}

export default MyApp;
