import fetch from 'isomorphic-unfetch';
import { Component, createContext, useContext } from 'react';

const FetchContext = createContext();
const { Provider } = FetchContext;

export class FetchProvider extends Component {
	constructor(props) {
		super(props);
		this.controlledFetch = this.controlledFetch.bind(this);
		this.state = {
			loading: false,
		};
	}

	async controlledFetch(url, options) {
		this.setState({ loading: true });

		try {
			const response = await fetch(url, options);
			const data = await response.json();

			return data || null;
		} finally {
			this.setState({ loading: false });
		}
	}

	render() {
		const { loading } = this.state;
		const { children } = this.props;

		return (
			<Provider value={{ loading, fetch: this.controlledFetch }}>
				{children}
			</Provider>
		);
	}
}

export function useRequestStatus() {
	const ctx = useContext(FetchContext);

	if (!ctx) {
		throw new Error(
			'`useContext` can only be called within a child of `FetchProvider`'
		);
	}
	return { loading: ctx.loading, fetch: ctx.fetch };
}
