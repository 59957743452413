import { DefaultToast } from 'react-toast-notifications';

import Notification from '../share-button/notification';

export default function CustomToast(props) {
	switch (props.appearance) {
	case 'copied':
		return <Notification />;
	default:
		return <DefaultToast {...props} />;
	}
}
